import React from 'react';
import Loadable from 'react-loadable';
import {
  CodeSnippet,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const AppleTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{}}
        code={`let toast:
  UniformToastModel(
    type: .confirmation,
    persistence: .dismiss,
    text: [toastText, toastLink],
    buttonsData: nil,
    style: .standard
  )`}
        platform="apple"
        gitHubLink="Toast"
        disableCodeEditing
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="UniformToastType" types={['string']}>
            <Text>Determines the type of toast.</Text>
            <List type="unordered">
              <li>
                <code>.critical</code>
              </li>
              <li>
                <code>.confirmation</code>
              </li>
              <li>
                <code>.information</code>
              </li>
              <li>
                <code>.warning</code>
              </li>
            </List>
          </PropListItem>
          <PropListItem name="UniformToastStyle" types={['string']}>
            <Text>
              Determines the display of the toast. The minimal style does not
              include the type icon.
            </Text>
            <List type="unordered">
              <li>
                <code>.minimal</code>
              </li>
              <li>
                <code>.standard</code>
              </li>
            </List>
          </PropListItem>
          <PropListItem name="UniformToastPersistence" types={['string']}>
            <Text>
              Determines whether you want the toast to display indefinitely
              (until swiped or dismissed) or to disappear after 3 seconds.
            </Text>
            <List type="unordered">
              <li>
                <code>.dismiss</code>
              </li>
              <li>
                <code>.timed</code>
              </li>
            </List>
          </PropListItem>
          <PropListItem name="UniformToastText" types={['array']}>
            <Text>
              The message that appears in the toast. Links can be included as
              well.
            </Text>
            <List type="unordered">
              <li>
                <code>paragraph</code>
              </li>
              <li>
                <code>link</code>
              </li>
            </List>
          </PropListItem>
          <PropListItem name="UniformToastButtonData" types={['array']}>
            <Text>The buttons to display below the toast message.</Text>
          </PropListItem>
          <PropListItem name="dismissedCallback" types={['func']}>
            <Text>The action called when the toast is dismissed.</Text>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            The <code>standard</code> style toast is preferred.
          </li>
          <li>
            If the text is short, make the action a link on the same line.
          </li>
          <li>
            If the text is long and other links are present, use buttons for
            main actions.
          </li>
          <li>
            Only use <code>minimal</code> style when in portrait mode with a
            long message.
          </li>
        </List>
      </Section>
    </PlatformTab>
  );
};

export default AppleTab;
