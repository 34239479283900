import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  CodeSnippet,
  List,
  PageNavigation,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
  AccessibilityLabelPartial,
  TestIdPartial,
  Link,
  Paragraph,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const snippetToast = require('raw-loader!../../../../data/snippets/rn-toast.example');
const snippetToastMessengerRoot = require('raw-loader!../../../../data/snippets/rn-toastmessenger-root.example');
// eslint-disable-next-line max-len
const snippetToastMessengerComponent = require('raw-loader!../../../../data/snippets/rn-toastmessenger-component.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <PageNavigation links={['Toast', 'Toast Messenger']} />

      <Section title="Toast">
        <CodeSnippet
          code={snippetToast}
          platform="react-native"
          gitHubLink="notifications/toast"
          disableCodeEditing
        />

        <Section title="Props">
          <PropList header="Visual">
            <PropListItem name="action" types={['Action']}>
              <Text>A subtle button that shows beneath the toast message.</Text>
              <List type="unordered">
                <li>
                  <code>text</code> - the text to display in the button
                </li>
                <li>
                  <code>onPress</code> - func to pass in to handle button click
                </li>
              </List>
            </PropListItem>

            <PropListItem name="duration" types={['string']}>
              <Text>
                Determines whether the toast auto-dismisses after 4 seconds (
                <code>"short"</code>
                ), 8 seconds (<code>"long"</code>
                ), or never (<code>"indefinite"</code>
                ). The default is <code>"indefinite"</code> for toasts used
                outside of a{' '}
                <Link href="#Toast%20Messenger">Toast Messenger</Link>. If used
                within a Toast Messenger, the default is <code>"short" </code>{' '}
                for toasts without an action, and <code>"long"</code> for toasts
                with an action. This does not apply to critical Toasts, which
                require user action to dismiss.
              </Text>
              <List type="unordered">
                <li>
                  <code>indefinite</code> (default outside of Toast Messenger)
                </li>
                <li>
                  <code>short</code> (4 seconds)
                </li>
                <li>
                  <code>long</code> (8 seconds)
                </li>
              </List>
            </PropListItem>

            <PropListItem name="isVisible" types={['boolean']}>
              <Text>Determines whether the toast is visible.</Text>
            </PropListItem>

            <PropListItem name="text" types={['string']}>
              <Text>The text that appears in the toast.</Text>
            </PropListItem>

            <PropListItem name="type" types={['string']} isRequired>
              <Text>Determines the type of toast.</Text>
              <List type="unordered">
                <li>
                  <code>information</code>
                </li>
                <li>
                  <code>confirmation</code>
                </li>
                <li>
                  <code>warning</code>
                </li>
                <li>
                  <code>critical</code>
                </li>
              </List>
            </PropListItem>
          </PropList>

          <PropList header="Events">
            <PropListItem name="onDismiss" types={['() => void']} isRequired>
              <Text>Responds to the toast being dismissed.</Text>
            </PropListItem>
          </PropList>

          <PropList header="Miscellaneous">
            <AccessibilityLabelPartial componentName="toast" />

            <TestIdPartial componentName="toast" />
          </PropList>
        </Section>
      </Section>

      <Section title="Toast Messenger">
        <Paragraph>
          The <code>ToastMessenger</code> is a component that is implemented
          once at the root level of your component tree and provides static
          methods to show and hide <code>Toasts</code>.
        </Paragraph>
        <CodeSnippet
          code={snippetToastMessengerRoot}
          platform="react-native"
          gitHubLink="notifications/toast/ToastMessenger.component.tsx"
          disableCodeEditing
        />
        <Paragraph>
          Once the <code>ToastMessenger</code> has been added to your project,
          call the <code>ToastMessenger.show()</code> method anywhere downstream
          to display a <code>Toast</code>. Call{' '}
          <code>ToastMessenger.hide()</code> to hide that <code>Toast</code>.
        </Paragraph>
        <Paragraph>
          Important note - the <code>Toast</code> by default has a{' '}
          <Link href="#duration">
            <code>duration</code>
          </Link>{' '}
          set.
        </Paragraph>
        <CodeSnippet
          code={snippetToastMessengerComponent}
          platform="react-native"
          gitHubLink="notifications/toast/ToastMessenger.component.tsx"
          disableCodeEditing
        />
        <Section title="Methods">
          <PropListItem
            name="show"
            types={['ToastMessenger.show({...config})']}>
            <Text>
              Shows a <code>Toast</code> using the config provided with the
              following shape:
            </Text>
            <List type="unordered">
              <li>
                <code>text: string</code>
              </li>
              <li>
                <code>
                  type: "information" | "confirmation" | "warning" | "critical"
                </code>{' '}
                (req)
              </li>
              <li>
                <code>
                  action: <Link href="#action">Action</Link>
                </code>
              </li>
              <li>
                <code>onDismiss: () => void</code>
              </li>
            </List>
          </PropListItem>
          <PropListItem name="hide" types={['ToastMessenger.hide()']}>
            <Text>
              Hides the currently displayed <code>Toast</code>.
            </Text>
          </PropListItem>
        </Section>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React Native"
        componentName="Notifications / Toast"
      />
    </PlatformTab>
  );
};

export default ReactNativeTab;
