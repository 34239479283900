import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ActionPartial,
  CodeSnippet,
  Link,
  List,
  OnDismissPartial,
  PageNavigation,
  Paragraph,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const Toast = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Toast;
    return <Component {...props} />;
  },
});

const Button = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Button;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/toast.example');
const snippetToastMessengerRoot = require('raw-loader!../../../../data/snippets/toastmessenger-root.example');
// eslint-disable-next-line max-len
const snippetToastMessengerComponent = require('raw-loader!../../../../data/snippets/toastmessenger-component.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Toast"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/notifications-toast--docs"
      />
      <PageNavigation links={['Toast', 'Toast Messenger']} />

      <Section title="Toast">
        <CodeSnippet
          scope={{ React: React, Toast: Toast, Button: Button }}
          code={snippet}
          platform="react"
          gitHubLink="notifications/toast"
        />

        <Section title="Props">
          <PropList header="Visual">
            <ActionPartial
              isSingleAction
              showText
              showOnClick
              showHref
              showTarget
              showQaid
            />

            <PropListItem name="duration" types={['string']}>
              <Text>
                Determines whether the toast auto-dismisses after 4 seconds (
                <code>"short"</code>
                ), 8 seconds (<code>"long"</code>
                ), or never (<code>"indefinite"</code>
                ). The default is <code>"short" </code> for toasts without an
                action, and <code>"long"</code> for toasts with an action. This
                does not apply to critical Toasts, which require user action to
                dismiss.
              </Text>
              <List type="unordered">
                <li>
                  <code>indefinite</code> (default outside of Toast Messenger)
                </li>
                <li>
                  <code>short</code> (4 seconds)
                </li>
                <li>
                  <code>long</code> (8 seconds)
                </li>
              </List>
            </PropListItem>

            <PropListItem name="text" types={['ReactNode']} isRequired>
              <Text>The text that appears in the toast.</Text>
            </PropListItem>

            <PropListItem name="type" types={['string']}>
              <Text>Determines the type of toast.</Text>
              <List type="unordered">
                <li>
                  <code>information</code>
                </li>
                <li>
                  <code>confirmation</code>
                </li>
                <li>
                  <code>warning</code>
                </li>
                <li>
                  <code>critical</code>
                </li>
              </List>
            </PropListItem>
          </PropList>

          <PropList header="Events">
            <OnDismissPartial componentName="toast" />
          </PropList>

          <PropList header="Miscellaneous">
            <QaIdPartial
              componentName="toast"
              passedDown={['dismiss', 'text']}
            />
          </PropList>
        </Section>
      </Section>

      <Section title="Toast Messenger">
        <Paragraph>
          The <code>ToastMessenger</code> is a component that is implemented
          once at the root level of your component tree and provides static
          methods to show and hide <code>Toasts</code>.
        </Paragraph>
        <CodeSnippet
          code={snippetToastMessengerRoot}
          platform="react"
          gitHubLink="notifications/toast/ToastMessenger.component.tsx"
          disableCodeEditing
          id="toastmessenger-root-snippet"
        />
        <Paragraph>
          Once the <code>ToastMessenger</code> has been added to your project,
          call the <code>ToastMessenger.show()</code> method anywhere downstream
          to display a <code>Toast</code>. Call{' '}
          <code>ToastMessenger.hide()</code> to hide that <code>Toast</code>.
        </Paragraph>
        <Paragraph>
          Important note - the <code>Toast</code> by default has a{' '}
          <Link href="#duration">
            <code>duration</code>
          </Link>{' '}
          set.
        </Paragraph>
        <CodeSnippet
          code={snippetToastMessengerComponent}
          platform="react"
          gitHubLink="notifications/toast/ToastMessenger.component.tsx"
          disableCodeEditing
          id="toastmessenger-snippet"
        />
        <Section title="Methods">
          <PropListItem
            name="show"
            types={['ToastMessenger.show({...config})']}>
            <Text>
              Shows a <code>Toast</code> using the config provided, which is an
              object that should include <Link href="#Props">Toast props</Link>.
            </Text>
          </PropListItem>
          <PropListItem name="hide" types={['ToastMessenger.hide()']}>
            <Text>
              Hides the currently displayed <code>Toast</code>.
            </Text>
          </PropListItem>
        </Section>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React"
        componentName="Notifications / Toast"
      />
    </PlatformTab>
  );
};

export default WebTab;
